/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Bootstrap grid */
@import "assets/css/bootstrap-grid.css";
@import "assets/css/bootstrap-utilities.css";

/* Variable */
$fontsize: 24px;
$fontsizeHeading1: 3em;
$fontsizeHeading2: 1.8em;
$fontsizeHeading3: 1.5em;
$fontsizeHeading4: 1.3em;
$fontsizeHeading5: 1.1em;
$fontsizeHeading6: 1em;
$fontsizeParagraph: 1em;
$fontsizeParagraphsmall: 0.9em;
$fontsizeParagraphxsmall: 0.8em;
$fontsizeParagraphxxsmall: 0.7em;

/* Root */
:root {
	--ion-text-color: #fff;
  --ion-font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;

  ion-header {
    --background: none;
    background: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  ion-toolbar {
    --background: none;
    background: none;
    --min-height: 8vh;
  }
  ion-back-button {
    width: 8vh;
    height: 8vh;
  }

  // Normalize fontsize
  ion-tab-button,
  ion-item,
  ion-title,
  ion-button,
  ion-back-button,
  .swiper-slide {
    font-size: $fontsize;
  }
}

/* Custom */
* {
	box-sizing: border-box;
}
*:focus {
  outline: none;
}

body {
  font-size: $fontsize;
  background: #fff;
  line-height: 1.5;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold !important;
  margin: 0 !important;
  margin-bottom: 16px;
  line-height: 1.4 !important;
}
h1,
.h1 {
  font-size: $fontsizeHeading1 !important;
}
h2,
.h2 {
  font-size: $fontsizeHeading2 !important;
}
h3,
.h3 {
  font-size: $fontsizeHeading3 !important;
}
h4,
.h4 {
  font-size: $fontsizeHeading4 !important;
}
h5,
.h5 {
  font-size: $fontsizeHeading5 !important;
}
h6,
.h6 {
  font-size: $fontsizeHeading6 !important;
}
p,
.p,
.psmall,
.pxsmall,
.pxxsmall {
  margin: 0;
  margin-bottom: 16px;
}
p,
.p {
  font-size: $fontsizeParagraph !important;
}
.psmall {
  font-size: $fontsizeParagraphsmall !important;
}
.pxsmall {
  font-size: $fontsizeParagraphxsmall !important;
}
.pxxsmall {
  font-size: $fontsizeParagraphxxsmall !important;
}

.bg {
  --background: none;
  background-image: url('/assets/media/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#logo {
  position: absolute;
  width: 15vw;
  top: 1vw;
  left: 2vw;
  img {
    display: block;
    width: 100%;
  }
}

#date-time {
  position: absolute;
  top: 1vw;
  right: 2vw;
  text-align: right;
  .date {
    font-size: $fontsizeHeading4;
  }
  .time {
    font-size: $fontsizeHeading1;
    line-height: 1;
    font-weight: bold;
    color: var(--ion-color-primary);
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.article {
  padding-top: 12vh;
  padding-bottom: 4vh;
  height: 100%;
  .row {
    height: 100%;
    > * {
      height: 100%;
    }
  }
  .article-detail {
    height: 100%;
    padding: 1em;
    border-radius: 1em;
    overflow: auto;
    color: var(--ion-color-dark);
    background: var(--ion-color-light);
  }
}

.btn {
  position: relative;
  background-image: url('/assets/media/btn.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 316px;
  .btn-img {
    img {
      display: block;
      width: 100%;
    }
  }
  .btn-text {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
  	color: var(--ion-color-dark);
  	padding-top: 2%;
  	padding-bottom: 8%;
  	font-weight: bold;
  }
}

.running-text {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff;
	width: 100vw;
	height: 6vh;
	display: flex;
	align-items: center;
	color: var(--ion-color-light);
	background: var(--ion-color-secondary);
	ul {
		height: 6vh;
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: flex;
		align-items: center;
		white-space: nowrap;
		li {
			margin-right: 2vw;
		}
	}
}

@media (max-width: 1366px) {
  $fontsize: 18px;
  body {
    font-size: $fontsize !important;
  }
  ion-tab-button,
  ion-item,
  ion-button,
  .swiper-slide {
    font-size: $fontsize !important;
  }

  .btn {
    max-width: 178px;
  }
}
